import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({ selector: '[storyblokLink]', standalone: true })
export class StoryblokLinkDirective {
  @Input() storyblokLink: any;
  constructor(private router: Router) {}

  @HostBinding('attr.href')
  get setup() {
    try {
      if (this.storyblokLink.url) {
        return this.storyblokLink.url.replace(/\/([a-z]{2}-[a-z]{2})\//i, '/');
      } else if (this.storyblokLink.cached_url) {
        return this.storyblokLink.cached_url.replace(
          /\/([a-z]{2}-[a-z]{2})\//i,
          '/'
        );
      } else {
        return (
          this.storyblokLink?.full_slug?.replace(
            /\/([a-z]{2}-[a-z]{2})\//i,
            '/'
          ) ??
          this.storyblokLink?.story?.full_slug?.replace(
            /\/([a-z]{2}-[a-z]{2})\//i,
            '/'
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  @HostListener('click', ['$event'])
  click($event: Event) {
    try {
      $event.preventDefault();
      $event.stopPropagation();
      if (this.storyblokLink.url) {
        if (this.storyblokLink.url.includes('http')) {
          window.location.href = this.storyblokLink.url.replace(
            /\/([a-z]{2}-[a-z]{2})\//i,
            '/'
          );
        } else {
          this.router.navigate([
            this.storyblokLink.url.replace(/\/([a-z]{2}-[a-z]{2})\//i, '/'),
          ]);
        }
      } else if (this.storyblokLink.cached_url) {
        this.router.navigate([
          this.storyblokLink.cached_url.replace(
            /\/([a-z]{2}-[a-z]{2})\//i,
            '/'
          ),
        ]);
      } else {
        this.router.navigate([
          this.storyblokLink?.full_slug?.replace(
            /\/([a-z]{2}-[a-z]{2})\//i,
            '/'
          ) ??
            this.storyblokLink?.story?.full_slug?.replace(
              /\/([a-z]{2}-[a-z]{2})\//i,
              '/'
            ),
        ]);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
